import React, { PureComponent } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    subject: "Algorithm",
    score: 120,
  },
  {
    subject: "Data Structure",
    score: 100,
  },
  {
    subject: "Backend",
    score: 90,
  },
  {
    subject: "Frontend",
    score: 80,
  },
  {
    subject: "Communication",
    score: 120,
  },
  {
    subject: "Critical Thinking",
    score: 100,
  },
];

export default class Chart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="90%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" />
          <Radar
            name="Mike"
            dataKey="score"
            stroke="#E9EDE8"
            fill="#A5D3DB"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    );
  }
}

// export default class Chart extends PureComponent {
//   render() {
//     return (
//       <ResponsiveContainer width="100%" height="100%">
//         <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
//           <PolarGrid />
//           <PolarAngleAxis dataKey="subject" />
//           <Radar
//             name="Mike"
//             dataKey="score"
//             stroke="#8884d8"
//             fill="#8884d8"
//             fillOpacity={0.6}
//           />
//         </RadarChart>
//       </ResponsiveContainer>
//     );
//   }
// }
