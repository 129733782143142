import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#121C2C",
      dark: "#4486b3",
      light: "#a6cde7",
      contrastText: "#E9EDE8",
    },
    secondary: {
      main: "#E9EDE8",
      dark: "#4486b3",
      light: "#a6cde7",
      contrastText: "#ffffff",
    },
    blue: {
      main: "#a5d3db",
      light: "#a5d3db",
      dark: "#4486b3",
      contrastText: "#4486b3",
    },
  },
  typography: {
    fontFamily: "Inconsolata",
    button: {
      textTransform: "none",
    },
  },
});
