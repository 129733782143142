import * as React from "react";
import { Container, Typography, Box } from "@mui/material";
import WorkProject from "./WorkProject";
import { theme } from "../styles/theme";

const data = [
  {
    title: "Nebula Engineering",
    url: "https://www.nebulaengineering.org",
    skills: "Javscript, React, AWS S3, AWS CloudFront",
    description:
      "A static website for Nebula Engineering, a New York based engineering consulting firm. The site is built using Javascript, React and Material UI. It is deployed using AWS S3 and CloudFront technology ",
    img: "/img/work/nebula.png",
  },
  {
    title: "SurfShop",
    url: "http://surfshop.s3-website-us-east-1.amazonaws.com",
    skills:
      "Java, Spring Boot, MySQL, Typescript, Angular, AWS S3, AWS Elastic Beanstalk, AWS RDS",
    description:
      "Created a Full-Stack E-Commerce Website utilizing Spring Boot back-end, Angular front-end, and MySQL database. User can search for products, add products to shopping cart, modify shopping cart, and check out. It is deployed using on AWS using RDS, Elastic Beanstalk and S3 Storage",
    img: "/img/work/surf.png",
  },
  {
    title: "MyReadingList",
    url: "https://myreadinglist.herokuapp.com/",
    skills: "Node.js, Express, MongoDb, Google OAuth, Google Books API",
    description:
      "Created a Full-Stack Application utilizing Node.js, Express, MongoDb, Mongoose, Google OAuth, Google Books API, Passport.js to help user keep track of their reading lists. User can use Google Books API to search for a book and save it to user’s own account. It is deployed via Heroku",
    img: "/img/work/book.png",
  },
];

function Work() {
  return (
    <Box id="work">
      <Container>
        <Typography
          variant="h2"
          color={theme.palette.blue.main}
          my={7}
          sx={{ fontWeight: "medium" }}
        >
          Work
        </Typography>
        {data.map((item) => (
          <WorkProject
            key={item.title}
            title={item.title}
            url={item.url}
            skills={item.skills}
            description={item.description}
            img={item.img}
          />
        ))}
      </Container>
    </Box>
  );
}

export default Work;
