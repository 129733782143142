import * as React from "react";
import { Container, Typography, Box, IconButton, Button } from "@mui/material";
import { theme } from "../styles/theme";

function Contact() {
  return (
    <Container>
      <Box
        id="contact"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          color={theme.palette.blue.main}
          mb={5}
          sx={{ fontWeight: "medium" }}
        >
          {" "}
          Get in touch!
        </Typography>
        <Typography variant="h5" mb={3}>
          {" "}
          Want to work together or have any questions?
        </Typography>
        <Box>
          <Button
            color="blue"
            size="large"
            href="mailto:"
            sx={{
              fontWeight: "medium",
              fontSize: "1.5rem",
              marginLeft: "-10px",
            }}
          >
            Say Hi
          </Button>
        </Box>
      </Box>
      <Typography
        variant="body1"
        justifySelf="end"
        alignItems="end"
        textAlign="center"
        padding={3}
      >
        Copyright © 2023 Linbo C.
      </Typography>
    </Container>
  );
}

export default Contact;
