import logo from "./logo.svg";
import "./App.css";
import React from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Intro from "./components/Intro";
import Navbar from "./components/Navbar";
import Work from "./components/Work";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Intro />
        <About />
        <Work />
        <Contact />
      </ThemeProvider>
    </div>
  );
}

export default App;
