import * as React from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { theme } from "../styles/theme";

const WorkProject = ({ title, url, skills, description, img }) => {
  return (
    <Grid container spacing={2} my={6} alignItems="flex-start">
      <Grid item xs={12} md={6}>
        <Box sx={{ display: "flex", alignItems: "center" }} mb={3}>
          <Typography
            variant="h4"
            color={theme.palette.blue.main}
            sx={{ fontWeight: "medium" }}
          >
            {title}
          </Typography>
          <IconButton href={url}>
            <InsertLinkIcon
              color="secondary"
              sx={{
                border: "1px #fff solid",
                borderRadius: "100%",
                padding: "3px",
              }}
            />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="body1" mb={3}>
            {description}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">Technologies used:</Typography>

          <Typography variant="body1">{skills}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <img src={img} alt={title} width={"100%"} />
      </Grid>
    </Grid>
  );
};

export default WorkProject;
