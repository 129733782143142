import { Container, Typography, Box, Button } from "@mui/material";
import * as React from "react";
import { theme } from "../styles/theme";
function Intro() {
  return (
    <Container>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" color={theme.palette.blue.main} mb={3}>
          Hi there! My Name is
        </Typography>
        <Typography variant="h1" my={3} sx={{ fontWeight: "medium" }}>
          Linbo Chen
        </Typography>
        <Typography variant="h5" color={theme.palette.blue.main} my={3}>
          I am a full-stack developer specialize in Java and JavaScript
          Frameworks
        </Typography>
        <Box>
          <Button
            color="blue"
            size="large"
            href="#work"
            sx={{
              fontWeight: "medium",
              fontSize: "1.5rem",
              marginLeft: "-10px",
            }}
          >
            Check out my works{" "}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Intro;
