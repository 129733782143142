import { Container, Typography, Box, Grid } from "@mui/material";
import * as React from "react";
import Chart from "./Chart";
import { theme } from "../styles/theme";

function About() {
  return (
    <Container>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          color={theme.palette.blue.main}
          mb={5}
          sx={{ fontWeight: "medium" }}
        >
          About
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} alignSelf={"center"}>
            <Typography variant="body1"> Hi there!</Typography>
            <Typography variant="body1">
              {" "}
              My name is Linbo and I am a full-stack developer specialize in
              Java and JavaScript Frameworks. I have demonstrated the ability to
              work on full-stack web development process through part-time job
              as web developer and a few personal projects, developing
              functional applications with clean, maintainable, and efficient
              code.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ minHeight: "350px" }}>
            <Chart />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default About;
